import React, { useState } from 'react'
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import './usuarios.scss';
import { AddAlert, Button, Checkbox, IcoAdd, IcoBin, IcoPencil, List, LoadingContent } from '../../components';
import { useEffect } from 'react';
import { modalOpen, verificaCreditoConta } from '../../layout/redux/layoutActions';
import { getUsuario, listarUsuarios, removerUsuario, setUsuario, setUsuariosPaginate } from './redux/usuariosActions';
import Add from './add';
import { IF, history } from '../../helpers';
import ObterMais from './obterMais';
import { listarMeusProjetos } from '../meusProjetos/redux/meusProjetosActions';


export default () => {
  const dispatch = useDispatch()
  const { sistema, usuario } = useSelector(state => state.layoutState)
  const nls = require(`./nls/${sistema.idioma}.json`)
  const { usuarios, paginate } = useSelector(state=> state.usuariosState)
  const { listMeusProjetos } = useSelector(state=> state.meusProjetosState)
  const [form, setForm] = useState({})
  const listaUser = usuarios.filter(e=> e.email !== usuario.email) 

  useEffect(()=> {
    dispatch([
      listarMeusProjetos(),
      listarUsuarios(paginate),
      verificaCreditoConta()
    ])
  }, [])

  useEffect(()=> {
    if (!usuario.permissoes?.VerGerenciarUsuarios) {
      history.push(`/meusprojetos`)
    }
  }, [usuario.permissoes])

  const headerDefault = [
    {
      column: 'checkboxCustom',
      text: <Checkbox 
        name='all'
        checked={form?.all}
        action={e=> handleCheckboxSelect({name: 'all', value: e})}
      />,
      className: 'actions',
    },
    {
      column: 'nomeCustom',
      text: nls?.tableNome,
      className: '',
    },
    {
      column: 'sobrenomeCustom',
      text: nls?.tableSobrenome,
      className: '',
    },
    {
      column: 'emailCustom',
      text: nls?.tableEmail,
      className: '',
    },
    {
      column: 'perfilCustom',
      text: nls?.tablePerfil,
      className: '',
    },
    {
      column: 'actions',
      text: '',
      className: 'actions',
    }
  ]

  const handleCheckboxSelect = e => {
    if (e.name === 'all') {
      let all = {all: e.value}
      listaUser.filter(f=> f.podeSerExcluidoConta).map(p=> {  all = p.podeSerExcluidoConta?{...all, [p.uniqueId]: e.value}:all })
      setForm({...form, ...all})
    } else {
      setForm({...form, [e.name]: e.value})
    }
  }

  const selectedHas = Object.keys(form).filter(p=> form[p] ).length

  const handlePaginate = e => {
    dispatch(listarUsuarios({...paginate, pageNumber: e}))
  }

  const handlePerPage = e => {
    dispatch(listarUsuarios({...paginate, pageNumber: 1, perPage: e.value.id}))
  }

  const popUsuarioPermissoes = e => {
    let permissaoUser = listMeusProjetos.map(p=> {
      let perm = {}
      if (e && typeof e.permissoes === 'Array') {
        perm = usuario.permissoes?.find(up=> String(up.id) === String(p.idProjeto))
      } else if (e && typeof e.permissoes === 'object') {
        
        e.permissoes?.filter(up=> String(up.id) === String(p.idProjeto)).map(up=> {
          if (up?.visualizar) {
            perm = {...perm, podeVisualizar: up?.visualizar}
          }
          if (up?.editar) {
            perm = {...perm, podeEditar: up?.editar}
          }
        })
      }
      return {...perm, id: p.idProjeto, nome: p.descricao, permissaoEditarProjeto: p.permissaoEditarProjeto}
    })

    dispatch(getUsuario({...e, permissoes: permissaoUser}))
  }
  
  const handleAddModal = e => {
    const hasCredit = !e ? sistema?.creditoConta?.creditoConviteUsuario : true
    
    popUsuarioPermissoes(e)
    if (hasCredit) {
      dispatch(modalOpen('criarProdutoMeuProjeto'))
    } else {
      dispatch(modalOpen('obter-mais-usuarios'))
    }
  }
  
  const handleRemove = e => {
    const returnedHas = Object.keys(e).filter(f=> f !== 'all' && e[f] === true)
    returnedHas.map(id=> {
      dispatch(removerUsuario({userId: id}))
    })
    handleCheckboxSelect({name: 'all', value: false})
  }
  
  return (
    <div className='box-gerenciar-usuarios'>
      <h2>{nls?.title}</h2>
      <div className='box-content'>
        <div className='box-content-topinfo'>
          <h5>{nls?.titleUsuariosList}</h5>
          <div>
            <Button
              type='link'
              color='default'
              onClick={()=> handleRemove(form)}
              disabled={selectedHas === 0? true:false}
            ><IcoBin /> {nls?.btnExcluir}</Button>
            <Button
              color='primary circle'
              onClick={()=> handleAddModal()}
            ><IcoAdd /> {nls?.btnAddUsuario}</Button>
          </div>
        </div>
        <LoadingContent
            show={false}
          >
            <List
              header={headerDefault}
              data={listaUser}
              listCustom={e=> {
                const item = e
                item.nomeCustom = e.nome?e.nome:'--'
                item.sobrenomeCustom = e.sobrenome?e.sobrenome:'--'
                item.emailCustom = e.email?e.email:'--'
                item.perfilCustom = e.perfil?.name?e.perfil?.name:'--'

                item.checkboxCustom = e.podeSerExcluidoConta?<>
                  <Checkbox 
                    name={e.uniqueId}
                    checked={form?.[e.uniqueId]}
                    action={c=> handleCheckboxSelect({name: e.uniqueId, value: c})}
                  />
                </>:null
                item.actions = e.status? <>
                  <Button
                    type='link'
                    color='default'
                    onClick={()=> handleAddModal(e)}
                  ><IcoPencil /> {nls?.btnEditar}</Button>
                </> :null

                return item
              }}
              paginate={paginate}
              actionPaginate={(e)=> handlePaginate(e)}
              handlePerPage={(e)=> handlePerPage(e)}
              nls={nls.paginacao}
            />
          </LoadingContent>
          <ObterMais/>
          <Add />
      </div>
    </div>
  )
}
