import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IcoEmojiInsatisfacao, IcoPaste, IcoShrink, IcoStar, Loading, Tab } from '../../../components'
import { useParams } from "react-router-dom";
import { history, numberAleatori } from '../../../helpers';

export default () => {
  const dispatch = useDispatch()
  const { IdSwot } = useParams()
  const { swots, loadSwots } = useSelector(state=> state.swotStateProdutos)
  const [IdSwotTab, setIdSwotTab] = useState('')

  useEffect(()=> {
    if (IdSwot) { setIdSwotTab(IdSwot) }
  }, [IdSwot])

  const handleSwot = (e) => {
    setIdSwotTab(e)
    history.push(`/analiseswotprodutos/${e}`)
  }
  
  const labelsType = [ 'Satisfação geral', 'Insatisfação geral', 'Pilares', 'Indicadores' ]
  const swotIcon = (e) => {
    switch (e) {
      case 'Satisfação geral':
        return <IcoStar />;
      case 'Insatisfação geral':
        return <IcoEmojiInsatisfacao />;
      case 'Pilares':
        return <IcoPaste />;
      case 'Indicadores':
        return <IcoShrink />;
      default:
        return <IcoStar />;
    }
  }

  // const tabContent = (e) => {
  //   const content = e?.map(tab=> {
  //     console.log(tab, 'tabContenttabContenttabContenttabContenttabContenttabContent');
    //   const swotsList = tab?.swot?.map(swot=> {
    //     const swotDatab = swot?.data.map(loja=> {
    //       return <div key={`tab-${tab.id}-swot${swot?.name}-loja-${loja.x}`} className='box-swot-content-info-list'>
    //         <span className='box-swot-content-info-list-loja'>{loja.x}</span>
    //         <p>{loja.y}</p>
    //       </div>
    //     })
    //     return <div key={`tab-${tab.id}-swot${swot?.name}`} className='box-swot-content-info'>
    //       <h3>{swotIcon(swot?.name)} {swot?.name}</h3>
    //         {swotDatab}
    //     </div>
    //   })

    //   return {
    //     id: tab.id,
    //     title: tab.title,
    //     content: <div className='box-swot-content'>
    //       <h2 className='box-swot-content-title'>{tab.title}</h2>
    //       {swotsList}
    //     </div>
    //   }
  //   })
  //   return content
  // }

  const tabContent = (e) => {
    const content = e?.map(tab=> {
      const swotsList = tab?.swot.map(swot=> {
        let swotDatab = swot.info
        if (typeof swot.info === 'string') {
          swotDatab = <div className='box-swot-content-info-list'>
              {swot.info.x? <span className='box-swot-content-info-list-loja'>{swot.info.x}</span>:null}
              <p>{swot.info}</p>
          </div>
        } else if ( typeof swot.info === 'object') {
          swotDatab = swot?.info.map(loja=> {
            return <div key={`tab-${tab.id}-swot${swot?.label}-loja-${loja}-${numberAleatori()}`} className='box-swot-content-info-list'>
            {loja.x? <span className='box-swot-content-info-list-loja'>{loja.x}</span>:null}
              <p>{loja}</p>
            </div>
          })
        }
        return <div key={`tab-${tab.id}-swot${swot?.label}`} className='box-swot-content-info'>
          <h3>{swotIcon(swot?.label)} {swot?.label}</h3>
            {swotDatab}
        </div>
      })

      return {
        id: tab.id,
        title: tab.title,
        content: <div className='box-swot-content'>
          <h2 className='box-swot-content-title'>{tab.title}</h2>
          {swotsList}
        </div>
      }
    })
    return content
  }
  
  return loadSwots?
  <Loading type='element' title='' height='300px' />
  :(
    <div className='box-swot'>
      <Tab
        actionTab={(e)=> handleSwot(e)}
        tabSelect={IdSwotTab}
        data={tabContent(swots)}
      />
    </div>
  )
}
