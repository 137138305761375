import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, IcoClose, IcoLogo, Menu } from '../components'

import { menuItens } from './menuItens'
import { setSidebarLeft, setTitlePage } from './redux/layoutActions'
import imgLogo from '../layout/img/logo-custom.png'

export default ({}) => {
  const dispatch = useDispatch()
  const {sidebarLeft} = useSelector(state => state.layoutState)

  const nlsMenu = menuItens()

  const [menuDash, setMenuDash ] = useState()
  
  useEffect(()=> {
    setMenuDash(nlsMenu)
  }, [])

  const handleSelect = ({sub}) => {
    if (!sub) {
      dispatch([
        setSidebarLeft(false)
      ])
    }
  }
  
  return (
    <div id='box-sidebar-left' className={sidebarLeft ? 'open-sidebar' : ''}>
      {/* <div className='box-logo'>
        <div>
          <img src={imgLogo} />
        </div> 
        <div className='box-logo-close'>
          <Button
            type='link'
            onClick={() => dispatch(setSidebarLeft(false))}
          >
            <IcoClose />
          </Button>
        </div>
      </div> */}
      <div className='box-sidebar-left-scrool'>
        <Menu data={menuDash} action={(e)=> handleSelect(e)} />
      </div>
    </div>
  )
}
