import { PaginateTotal } from '../../../components'

const paginateInit = {
  pageNumber: 1, 
  totalPages: 1, 
  totalElements: 0,
  perPage: 7
}

const initialState = {
  listMeusProjetos: [],
  paginate: paginateInit,
  meuProjeto: {
    projeto: {
      descricao: '',
      icone: '',
      nome: '',
      segmento: '',
      tipo: '',
      status: '',
      licenca: ''
    },
    empresas: [],
    empresasPaginate: paginateInit,
    estabelecimentos: [],
    estabelecimentosPaginate: paginateInit,
    produtos: [],
    produtosPaginate: paginateInit,
    gruposProdutos: [],
    gruposEstabelecimentos: [],
    subsegmentos: [],
    estabelcimentosVerificar: [],
  },
  erro: {},
  load: {}
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'LIST_MEUS_PROJETOS':
      return { ...state, listMeusProjetos: payload.informacoesProjetos, paginate: {...state.paginate, totalElements: payload.totalProjetos } }
    case 'SET_MEUS_PROJETOS':
      return { ...state, listMeusProjetos: [payload, ...state.listMeusProjetos] }
    case 'PUT_MEUS_PROJETOS':
      return { ...state, listMeusProjetos: state.listMeusProjetos.map(lp=> Number(lp.idProjeto) === Number(payload.idProjeto)?{...lp, ...payload}: lp), meuProjeto: {...state.meuProjeto, projeto: {...state.meuProjeto.projeto, ...payload}} }
    case 'SET_FORM_MEUS_PROJETO_LIST':
      return { ...state, meuProjeto: {...state.meuProjeto, [payload.name]: payload.value} }
    case 'SET_FORM_MEUS_PROJETO':
      return { ...state, meuProjeto: {...state.meuProjeto, [payload.tipo]: {...state.meuProjeto[payload.tipo],[payload.name]: payload.value}} }
    case 'CLEAN_FORM_MEUS_PROJETO':
      return { ...state, meuProjeto: initialState.meuProjeto }
    case 'SET_MEUS_PROJETO_ERRO':
      return { ...state, erro: payload }


      
    case 'SET_MEUS_PROJETOS_TAB':
      return { ...state, meuProjeto: {...state.meuProjeto, [payload.name]:[payload.value, ...state.meuProjeto[payload.name]]} }
    case 'PUT_MEUS_PROJETOS_TAB':
      return { ...state, meuProjeto: {...state.meuProjeto, [payload.name]: state.meuProjeto[payload.name].map(e=> String(e[payload.id]) === String(payload.value[payload.id]) ? {...e, ...payload.value}: e)} }
    case 'REMOVE_MEUS_PROJETOS_TAB':
      return { ...state, meuProjeto: {...state.meuProjeto, [payload.name]: state.meuProjeto[payload.name].filter(e=> String(e[payload.id]) !== String(payload[payload.id]))} }
    case 'SET_MEUS_PROJETO_LOAD':
      return { ...state, load: {[payload.name]: payload.value} }

    default:
      return state
  }
}
