import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IcoStar, Select } from '../../components'
import './comentarios.scss'
import Filter from './filter/filter'
import { setInfoGrupo, setProjeto } from '../../layout/redux/layoutActions'
import Tabela from './tabela'
import { filterHandle, listarComentarios, setSobreComentario, setPaginateComentarios } from './redux/comentariosActions'




export default () => {
  const dispatch = useDispatch()
  const { grupos, grupo, filters, empresa, categoria, sistema } = useSelector(state=> state.layoutState)
  const { projeto, filtersProvisorio, projetos, paginate, totalAvaliacao, sobreComentarios, sobreComentario } = useSelector(state=> state.comentariosnovosState)

  const initialStatePaginate = { 
    pageNumber: 1, 
    totalPages: 1, 
    totalElements: 0,
    perPage: 7
  }
  

  const nls = require(`./nls/${sistema.idioma}.json`)
  console.error(sistema.idioma,'sistema.idioma em comentnovo')


  useEffect(()=> {
    if (projeto.id) {
      if (filters.typeSelect) {
        //dispatch(setPaginateComentarios(initialStatePaginate))
        //dispatch(filterHandle({projetoID: projeto.id, grupo, params: filters, filtersProvisorio, typeSelect: filters.typeSelect, paginate, idioma: sistema.idiomaIdValue, tipo: sobreComentario.tipo}))
        dispatch(filterHandle({projetoID: projeto.id, grupo, params: filters, filtersProvisorio, typeSelect: filters.typeSelect, idioma: sistema.idiomaIdValue, tipo: sobreComentario.tipo}))
      } else {
        dispatch(listarComentarios({projetoID: projeto.id}))
      }
    }
  }, [projeto, empresa, categoria, sobreComentario])

  const handleFormProjeto = (e) => {
    dispatch(setProjeto(e.value, sistema.idioma))
  }
  
  const handleFormAssunto = (e) => {
    console.error(e.value,'SOBRECOMENTARIO')
    dispatch(setSobreComentario(e.value))
  }

  //console.error(sobreComentarios,'SOBRECOMENTARIOS')
  //console.error(nls.sobrecomentarios,'SOBRECOMENTARIOSCOMNLS')

  if(sobreComentario.id === 1)
    sobreComentario.assunto = nls.estabelecimentoassunto
  else if(sobreComentario.id === 2)
    sobreComentario.assunto = nls.produtoassunto
  else 
    sobreComentario.assunto = nls.empresaassunto

  return (
    <div className='box-comentarios'>
      <div className='box-comentarios-topinfo'>
        <div>
          <div className='box-comentarios-topinfo-select'>
            <Select 
              name='projeto'
              label={nls.projeto}
              options={projetos}
              selected={projeto}
              action={(e)=> handleFormProjeto(e)}
              selectedItem={false}
              optionLabel='nomeProjeto'
              optionValue='id'
            />
          </div>

          <div className='box-comentarios-topinfo-select'>
            <Select 
              name='assunto'
              label={nls.assunto}
              options={nls.sobrecomentarios}
              selected={sobreComentario}
              action={(e)=> handleFormAssunto(e)}
              selectedItem={false}
              optionLabel='assunto'
              optionValue='id'
            />
          </div>         
        </div>
        <div className='box-comentarios-topinfo-avaliacao'>
            <label>{nls.numerodeavaliacoes}</label>
            <IcoStar />
            <strong>{totalAvaliacao?totalAvaliacao:0}</strong>
        </div>
      </div>
      <Filter />
      <Tabela />
    </div>
  )
}
