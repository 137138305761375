import React, { useEffect } from 'react'
import { Router, Route, Switch, HashRouter } from 'react-router-dom';
import { history } from '../helpers/history';
import AuthLayout from '../layout/layout';
import { getIp, titlePage } from '../layout/redux/layoutActions';
import { menuItens } from '../layout/menuItens';
import { useDispatch, useSelector } from 'react-redux';

import paginaAlerta from './alertPage';
import errorPage from './errorPage';
import emDesenvolvimento from './emDesenvolvimento';
import login from '../modules/login/login';
import recupararSenha from '../modules/login/recupararSenha';

import basededados from '../modules/basededados/basededados';

import csatcliente from '../modules/empresa/csatcliente/csatcliente';
import npscliente from '../modules/empresa/npscliente/npscliente';
import rankingclientecsat from '../modules/empresa/rankingclientecsat/rankingclientecsat';
import rankingclientenps from '../modules/empresa/rankingclientenps/rankingclientenps';
import csatconcorrentes from '../modules/empresa/csatconcorrentes/csatconcorrentes';

import infoGrupo from '../modules/grupos/infoGrupo/infoGrupo';
import rankingcsat from '../modules/grupos/rankingcsat/rankingcsat';
import rankingnps from '../modules/grupos/rankingnps/rankingnps';
import indicadores from '../modules/grupos/indicadores/indicadores';
import swot from '../modules/grupos/swot/swot';

import comentarios from '../modules/comentarios/comentarios';
import npsconcorrentes from '../modules/empresa/npsconcorrentes/npsconcorrentes';
import meusProjetos from '../modules/meusProjetos/meusProjetos';
import configurar from '../modules/meusProjetos/configurar/configurar';
import usuarios from '../modules/usuarios/usuarios';
import projetos from '../modules/projetos/projetos';
import cadastro from '../modules/login/cadastro';
import redescsatcliente from '../modules/redes/csatcliente/csatcliente';
import redesnpscliente from '../modules/redes/npscliente/npscliente';
import redescsatconcorrentes from '../modules/redes/csatconcorrentes/csatconcorrentesredes';
import redesnpsconcorrentes from '../modules/redes/npsconcorrentes/npsconcorrentesredes';
import indicadoresredes from '../modules/redes/indicadores/indicadores';

//PRODUTOS COMENTARIOS
import produtoscsatcliente from '../modules/produtos/csatcliente/csatcliente';
import produtosnpscliente from '../modules/produtos/npscliente/npscliente';
import produtoscsatconcorrentes from '../modules/produtos/csatconcorrentes/csatconcorrentesprodutos';
import produtosnpsconcorrentes from '../modules/produtos/npsconcorrentes/npsconcorrentesprodutos';
import indicadoresprodutos from '../modules/produtos/indicadores/indicadores';
import swotprodutos from '../modules/produtos/swot/swot';
import infoGrupoProdutos from '../modules/produtos/infoGrupo/infoGrupo';
import comentariosnovos from '../modules/comentnovo/comentarios';
import plano7dias from '../modules/planos/plano7dias/plano7dias';

import minhasAssinaturas from '../modules/minhasAssinaturas/minhasAssinaturas';
import upgradeConta from '../modules/upgradeConta/upgradeConta';

const PrivateRoute = ({ component: Component, path, sidebar=false }) => {
  const dispatch = useDispatch()
  const pathname =  window.location.pathname
  
  const scrollToTop = () => {
    const content = document.getElementById('box-container')
    content?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  useEffect(()=> {
    dispatch(titlePage({pathname, menuItens: menuItens()}))
    scrollToTop()
  },[pathname])

  return (
    <Route
      exact
      path={path}
      render={props =>
        <AuthLayout sidebar={sidebar}>
          <Component {...props} />
        </AuthLayout>
      }
    />
  )
}

export default () => {

  return (
    <Router history={history}>
      <Switch>
        <Route path='/' exact component={login} />
        <Route path='/login' exact component={login} />
        <Route path='/cadastro' exact component={cadastro} />
        <Route path='/recuperarsenha' exact component={recupararSenha} />
        <Route path='/recuperarsenha/:tolkenRecuperar' exact component={recupararSenha} />
        
        <Route path='/ResetPassword' exact component={recupararSenha} />
        <Route path='/ResetPassword/:tolkenRecuperar' exact component={recupararSenha} />
        


        <PrivateRoute path='/checkoutsucesso' exact component={paginaAlerta} />

        <PrivateRoute path='/meusprojetos' exact component={meusProjetos} />
        <PrivateRoute path='/meusprojetos/:idProjetos' exact component={meusProjetos} />
        <PrivateRoute path='/meusprojetos/:idProjetos/configurar' exact component={configurar} />
        <PrivateRoute path='/meusprojetos/:idProjetos/configurar/:idPasso' exact component={configurar} />

        
        <PrivateRoute path='/minhasassinaruras' exact component={minhasAssinaturas} />
        <PrivateRoute path='/upgradeconta' exact component={upgradeConta} />

        <PrivateRoute path='/usuarios' exact component={usuarios} />
        <PrivateRoute path='/projetos' exact component={projetos} />




        <PrivateRoute path='/resumoprojeto' exact component={basededados} sidebar={true} />

        <PrivateRoute path='/csat' exact component={csatcliente} sidebar={true} />
        <PrivateRoute path='/nps' exact component={npscliente} sidebar={true} />
        <PrivateRoute path='/csatestabelecimentos' exact component={rankingclientecsat} sidebar={true} />
        <PrivateRoute path='/npsestabelecimentos' exact component={rankingclientenps} sidebar={true} />
        <PrivateRoute path='/csatconcorrentes' exact component={csatconcorrentes} sidebar={true} />
        <PrivateRoute path='/npstconcorrentes' exact component={npsconcorrentes} sidebar={true} />
        
        <PrivateRoute path='/csatredescsatcliente' exact component={redescsatcliente} sidebar={true} />
        <PrivateRoute path='/npsredescsatcliente' exact component={redesnpscliente} sidebar={true} />
        <PrivateRoute path='/csatredesconcorrentes' exact component={redescsatconcorrentes} sidebar={true} />
        <PrivateRoute path='/npsredesconcorrentes' exact component={redesnpsconcorrentes} sidebar={true} />


        <PrivateRoute path='/indicadoresredes' exact component={indicadoresredes} sidebar={true} />

        {/* PRODUTOS */}
        <PrivateRoute path='/csatprodutoscsatcliente' exact component={produtoscsatcliente} sidebar={true} />
        <PrivateRoute path='/npsprodutosnpscliente' exact component={produtosnpscliente} sidebar={true} />
        <PrivateRoute path='/csatprodutosconcorrentes' exact component={produtoscsatconcorrentes} sidebar={true} />
        <PrivateRoute path='/npsprodutosconcorrentes' exact component={produtosnpsconcorrentes} sidebar={true} />
        <PrivateRoute path='/indicadoresprodutos' exact component={indicadoresprodutos} sidebar={true} />
        <PrivateRoute path='/analiseswotprodutos' exact component={swotprodutos} sidebar={true} />
        <PrivateRoute path='/analiseswotprodutos/:IdSwot' exact component={swotprodutos} sidebar={true} />
        <PrivateRoute path='/resumogruposprodutos' exact component={infoGrupoProdutos} sidebar={true} />

        {/* COMENTÁRIOS */}
        <PrivateRoute path='/comentariosnovos' exact component={comentariosnovos} sidebar={true} />

        {/* PLANOS */}
        <PrivateRoute path='/pl7' exact component={plano7dias} sidebar={true} />



        <PrivateRoute path='/resumogrupos' exact component={infoGrupo} sidebar={true} />
        <PrivateRoute path='/csatestabelecimentosconcorrentes' exact component={rankingcsat} sidebar={true} />
        <PrivateRoute path='/npsestabelecimentosconcorrentes' exact component={rankingnps} sidebar={true} />
        <PrivateRoute path='/indicadores' exact component={indicadores} sidebar={true} />
        <PrivateRoute path='/analiseswot' exact component={swot} sidebar={true} />
        <PrivateRoute path='/analiseswot/:IdSwot' exact component={swot} sidebar={true} />
        
        <PrivateRoute path='/comentarios' exact component={comentarios} sidebar={true} />

        <PrivateRoute path='/grupo1' exact component={emDesenvolvimento} sidebar={true} />



        <Route component={errorPage} />
      </Switch>
    </Router>
  )
}