import { dateCustom } from '../../../../components';
import { graficoCustom, numberFormat, numberZero, paramsApi } from '../../../../helpers';
import api from '../../../../helpers/api';
import apiOld from '../../../../helpers/apiOld';
import { setFiltered } from '../../../../layout/redux/layoutActions';


export const setTotalAvaliacao = payload => ({
  type: 'INFO_GRAFICO_RANKINGCSAT_TOTAL_AVALIACAO',
  payload
})


export const setFiltersProvisorio = payload => ({
  type: 'INFO_FILTERS_RANKINGCSAT',
  payload
})

export const putFiltersProvisorio = payload => ({
  type: 'CHANGE_FILTERS_RANKINGCSAT',
  payload
})

export const cleanFiltersProvisorio = () => ({
  type: 'CLEAN_FILTERS_RANKINGCSAT',
})


export const setGrafico = payload => ({
  type: 'INFO_GRAFICO_RANKINGCSAT',
  payload
})

export const setGraficoLoad = payload => ({
  type: 'LOAD_GRAFICO_RANKINGCSAT',
  payload
})

export const carregarGrafico = ({projetoID, grupo, params}) => {
  
  return dispatch => {
    dispatch(setGraficoLoad(true))
    let paramsUrl = paramsApi({
      ...params, 
      projetoId: projetoID,
      Grupo: grupo.nomeGrupo,
    })
    apiOld.get(`/front/GetRankingCsat${paramsUrl}`)
    .then(result => {
      const resultado = graficoCustom(result.data, '', true)
      dispatch([
        setGrafico(resultado),
        setTotalAvaliacao(result.data.totalAvaliacoes),
        setGraficoLoad(false)
      ])
    })
    .catch(err => {
      console.error(err);
      dispatch([
        setGrafico([]),
        setTotalAvaliacao(0),
        setGraficoLoad(false)
      ])
    });
  }
}
// {typeSelect: 'Ciclo', MesCliclo: '2023-01', Comparacao: false}
export const filterHandle = ({projetoID, grupo, params, typeSelect, filtersProvisorio}) => {
  const paramsCustom = {...params, ciclo: params?.ciclo?.ano ? params.ciclo : filtersProvisorio.ciclo}
  return dispatch => {
    let paramsFilter = filtersCustom({params: paramsCustom, typeSelect})
    dispatch(setGraficoLoad(true))
    dispatch([
      setFiltered(paramsCustom),
      carregarGrafico({projetoID, grupo, params: paramsFilter, typeSelect})
    ])
  }
}

export const filtersCustom = ({params, typeSelect}) => {
  let paramsFilter = {}
  if (typeSelect?.id === 'Ciclo') {
    if (params?.ciclo?.mesAno) {
      paramsFilter = {
        comparacao: params.compararCiclos.id === 'ativar'?true:false,
        intervalo: `${params.ciclo.ano}-${params.ciclo.mes}`,
      }
    }
  }
  if (typeSelect?.id === 'Data') {
    paramsFilter = {
      intervalo: `${dateCustom(params.dataInicial).data} ${dateCustom(params.dataFinal).data}`,
    }
  }
  return paramsFilter
}