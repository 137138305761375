import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, IcoCalendar, IcoCicle, Select } from '../../../components'
import './filter.scss'
import FilterCiclo from './filterCiclo'
import FilterData from './filterData'
import { filterHandle, putFiltersProvisorio, setFiltersProvisorio } from '../redux/comentariosActions'

export default () => {
  const dispatch = useDispatch()
  const { projeto, filters, listas: {typeSearch}, empresas, categorias, ciclos, grupo, sistema } = useSelector(state=> state.layoutState)
  const { filtersProvisorio, paginate, sobreComentarios, sobreComentario  } = useSelector(state=> state.comentariosnovosState)

  const nls = require(`../nls/${sistema.idioma}.json`)


  useEffect(()=> {
    let filtros = filters
    if (!filters?.ciclo?.anoMes  && ciclos.length) {
      filtros = {...filtros, ciclo: ciclos[ciclos.length - 1]}
    }
    dispatch(setFiltersProvisorio(filtros))
  }, [ciclos])

  const handleSearching = (params) => {
    //dispatch(filterHandle({projetoID: projeto.id, grupo, params, typeSelect: params.typeSelect, filtersProvisorio, paginate, idioma: sistema.idiomaIdValue, tipo: sobreComentario.tipo}))
    dispatch(filterHandle({projetoID: projeto.id, grupo, params, typeSelect: params.typeSelect, filtersProvisorio, idioma: sistema.idiomaIdValue, tipo: sobreComentario.tipo}))
  
  }
  
  const contentFilterType = (e) => {
    switch (e?.id) {
      case 'Ciclo':
        return <FilterCiclo />;
      case 'Data':
        return <FilterData />;
      default:
        return;
    }
  }

  const handleFilter = (e) => {
    dispatch(putFiltersProvisorio(e))
  }
  

  

  const categoriasWithTodas = [
    {
      id: 0,
      descricao: nls.todascategorias,
      ordem: 1
    },
    ...categorias // Spread the original categorias array
  ];

  
  const empresasWithTodas = [
    {
      empresaid: 0,
      descricaoEmpresa: nls.todasempresas,
      ordem: 1
    },
    ...empresas // Spread the original empresas array
  ];




  return (
    <div className='box-comentarios-search box-content'>
      <div className='box-comentarios-search-box'>
        <div className='box-comentarios-search-type'>
          {
            typeSearch.map((type)=> {
              return <Button
                color={'primary'}
                type={type.id === filtersProvisorio?.typeSelect?.id?'btn':'link'}
                size='small'
                onClick={()=> dispatch(putFiltersProvisorio({name: 'typeSelect', value: type}))}
                key={type.id}
              >
                {type.label}
              </Button> 
            })
          }
          <p>{filtersProvisorio?.typeSelect?.text === 'Filtrar por Ciclo' ? nls.filtrarporciclo : nls.filtrarpordata}</p>
          
        </div>
        <div>
          {contentFilterType(filtersProvisorio?.typeSelect)}
        </div>

      </div>
      <div className='box-comentarios-search-action'>
          <div className='box-comentarios-search-select'>
            <Select
              name='empresa'
              options={empresasWithTodas}             
              selected={Object.keys(filtersProvisorio?.empresa).length === 0 ? empresasWithTodas[0] : filtersProvisorio?.empresa}             
              action={(e)=> handleFilter(e)}
              optionLabel='descricaoEmpresa'
              optionValue='empresaId'
              textCustom={['Todas empresas', 'Selecionado', 'Selecionados', 'Marcar todos']}
              selectedItem={false}
            />
          </div>
          <div className='box-comentarios-search-select'>
            <Select
              name='categoria'
              options={categoriasWithTodas}
              selected={Object.keys(filtersProvisorio?.categoria).length === 0 ? categoriasWithTodas[0] : filtersProvisorio?.categoria}
              action={(e)=> handleFilter(e)}
              optionLabel='descricao'
              optionValue='id'
              textCustom={['Todas categorias', 'Selecionado', 'Selecionados', 'Marcar todos']}
              selectedItem={false}
            />
          </div>
        <Button
          color='primary'
          size='large'
          onClick={()=> handleSearching(filtersProvisorio)}
        >{nls.pesquisar}</Button>          
      </div>
    </div>
  )
}
