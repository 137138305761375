import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Input, Modal } from '../../../components'
import { modalAlertaInfo, modalClose } from '../../../layout/redux/layoutActions'
import { EconomizarAssinaturaContaXD, EconomizarAssinaturaProjetoProntoXD, listaAlterarAssinatura, listaEconomizarAssinatura, listaPeriodos } from '../redux/minhasAssinaturasActions'

export default () => {
  const dispatch = useDispatch()
  const { sistema, usuario, statusModal } = useSelector(state => state.layoutState)
  const { assinatura, listas } = useSelector(state=> state.minhasAssinaturasState)
  const nls = require(`../nls/${sistema.idioma}.json`)
  const nlsPopup = require(`../../../layout/nlsPopup/${sistema.idioma}.json`)
  const [selected, setSelected] = useState({})

  useEffect(()=> {
    if (statusModal === 'assinaturas-economizar') {
      if (assinatura.idProjetoPronto === null) {
        dispatch(listaEconomizarAssinatura({moeda: usuario.moedaIso4217}))      
        dispatch(listaPeriodos())
      } else {
        dispatch(listaAlterarAssinatura({id: assinatura.idProjetoPronto,moeda: usuario.moedaIso4217}))      
        dispatch(listaPeriodos())
      }
    }
  }, [statusModal, assinatura.idProjetoPronto])

  const listaInfoEconomiza = assinatura.idProjetoPronto === null ? listas?.economizarAssinatura : listas.alterarAssinaturas.filter(f=> f.
    idPeriodo > assinatura.periodoId)

  const handleCancelar = e => {
    dispatch(modalClose())
  }

  const handleConcordar = e => {
    console.error(e, 'assinaturas-economizar handleConcordar');
    const params = {
      projetoId: assinatura.idProjetoPronto,
      idPeriodo: e.idPeriodo,
      moeda: usuario.moedaIso4217
    }
    if (assinatura.idProjetoPronto === null) {
      dispatch(EconomizarAssinaturaContaXD(params, nlsPopup))
    } else {
      dispatch(EconomizarAssinaturaProjetoProntoXD(params, nlsPopup))
    }
  }
  
  const handleSalvar = e => {
    console.warn(e,assinatura,  'assinaturas-economizar');
    if (e.idPeriodo) {
      dispatch(modalAlertaInfo({
        ...nlsPopup[assinatura.idProjetoPronto === null ? '3' : '4'], 
        btnAction: ()=> handleConcordar(e, 'assinaturas-economizar'),
        show: true,
        textos: {
          valorPagoAgora: e.valorPagoAgora,
          valor: e.valor,
        }
      }))
      handleCancelar()
    }
  }
  
  
  return (
    <Modal
      name='assinaturas-economizar'
      title={nls.titleEconomizarAssinatura}
      open={statusModal === 'assinaturas-economizar' ? true : false}
      close={() => handleCancelar()}
      closeText={nls.btnFechar}
      actions={<>
        <Button
          color='default'
          onClick={()=> handleCancelar()}
        >{nls.cancelar}</Button>
        <Button
          color='primary'
          onClick={()=> handleSalvar(selected)}
        >{nls.economizar}</Button>
      </>}

    >
      <div className='assinaturas-lista'>
        <p><small>{nls.periodoAtual}: {assinatura.periodo}</small></p>
        
        <div className='box-scrool'>
          <table className='list-box'>
            <thead>
              <tr>
                <td>{nls.periodo}</td>
                <td>{nls.valor}</td>
                <td>{nls.desconto}</td>
              </tr>
            </thead>
            <tbody>
              {listaInfoEconomiza?.map(e=> {
                const desconto = listas?.periodos.find(f=> f.id === e.
                  idPeriodo)
                return <tr key={e.idPeriodo} className={`assinaturas-lista-item ${e.idPeriodo === selected.idPeriodo ? 'ativo': ''}`} onClick={c=> setSelected(e)}>
                <td>{e.descricao}</td>
                <td>{e.valor}</td>
                <td className='text-primary'>{desconto?.percentualDesconto}</td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  )
}
