import axios from 'axios';
import { gerarFiltros, gerarLista } from './projetos';
import { idRdm, numberAleatori, paramsApi } from '../../../helpers';
import apiBase from '../../../helpers/apiBase';
import { modalAlertaClean, modalAlertaInfo, modalClose } from '../../../layout/redux/layoutActions';

export const getProjetos = payload => ({
  type: 'GET_PROJETOS',
  payload
})

export const setProjetosPaginate = payload => ({
  type: 'SET_PROJETOS_PAGINATE',
  payload
})

export const cleanProjetosPaginate = payload => ({
  type: 'CLEAN_PROJETOS_PAGINATE',
  payload
})

export const getProjeto = payload => ({
  type: 'GET_PROJETO',
  payload
})

export const postProjeto = payload => ({
  type: 'POST_PROJETO',
  payload
})

export const putProjeto = payload => ({
  type: 'PUT_PROJETO',
  payload
})

export const deleteProjeto = payload => ({
  type: 'DELETE_PROJETO',
  payload
})

export const setProjeto = payload => ({
  type: 'SET_PROJETO',
  payload
})

export const cleanProjeto = payload => ({
  type: 'CLEAN_PROJETO',
  payload
})

export const loadProjetos = payload => ({
  type: 'LOAD_PROJETOS',
  payload
})

export const errorProjetos = payload => ({
  type: 'ERROR_PROJETOS',
  payload
})

export const listarProjetos = e => {
  const params = paramsApi(e)
  return dispatch => {
    apiBase.get(`/v4/api/ListaProjetosProntos${params}`)
    .then(({data}) => {
      console.log(data, 'listarProjetos data');
      dispatch(getProjetos(data))
    })
    .catch(err => {
        console.error(err, 'listarProjetos');
    });
  }
}

export const criarProjeto = e => {
  return dispatch => {
    dispatch(postProjeto({...e, id: idRdm(), status: true}))
  }
}

export const editarProjeto = e => {
  return dispatch => {
    dispatch(putProjeto(e))
  }
}

export const removerProjeto = e => {
  return dispatch => {
    dispatch(deleteProjeto(e))
  }
}

export const getProjetosFiltros = payload => ({
  type: 'GET_PROJETOS_FILTROS',
  payload
})

export const getProjetosFiltro = payload => ({
  type: 'GET_PROJETOS_FILTRO',
  payload
})

export const setProjetosFiltro = payload => ({
  type: 'SET_PROJETOS_FILTRO',
  payload
})


export const CheckoutProjetoProntoXD = (e, nlsPopup) => {
  const params = paramsApi(e)
  return dispatch => {
    apiBase.get(`/v4/api/CheckoutProjetoProntoXD${params}`)
    .then(({data}) => {
      window.open(data.urlCheckout, '_blank');
      dispatch(modalClose())
    })
    .catch(err => {
        console.error(err, 'CheckoutProjetoProntoXD');
        dispatch(modalClose())
        dispatch(modalAlertaInfo({
          ...nlsPopup['7'], 
          btnAction: ()=> dispatch(modalAlertaClean()),
          show: true,
        }))
    });
  }
}




    // axios.get(``)
    // .then(result => {
    //     console.warn(result, 'listarProjetos');
    // })
    // .catch(err => {
    //     console.error(err, 'listarProjetos');
    // });

