import api from '../../../../helpers/api';
import { numberZero, paramsApi } from '../../../../helpers';
import { dateCustom } from '../../../../components';
import { setFiltered, setFiltersActive } from '../../../../layout/redux/layoutActions';
import apiOld from '../../../../helpers/apiOld';
import apiV3 from '../../../../helpers/apiV3';

export const setInfoTotalAvaliacao = payload => ({
  type: 'INFO_GRUPO_TOTAL_AVALIACAO',
  payload
})

export const setListarInfoGrupo = payload => ({
  type: 'LIST_INFO_GRUPO',
  payload
})

export const setPaginateInfoGrupo = payload => ({
  type: 'PAGINATE_INFO_GRUPO',
  payload
})


export const setFiltersProvisorio = payload => ({
  type: 'INFO_FILTERS_INFO_GRUPO',
  payload
})

export const putFiltersProvisorio = payload => ({
  type: 'CHANGE_FILTERS_INFO_GRUPO',
  payload
})

export const cleanFiltersProvisorio = () => ({
  type: 'CLEAN_FILTERS_INFO_GRUPO',
})


export const setLoadList = payload => ({
  type: 'LOAD_LIST_INFO_GRUPO',
  payload
})

export const filtersCustom = ({filters, typeSelect}) => {
  let paramsFilter = {}
  if (typeSelect?.id === 'Ciclo') {
    if (filters?.ciclo?.mesAno) {
      paramsFilter = {
        comparacao: filters.compararCiclos.id === 'ativar'?true:false,
        intervalo: `${filters.ciclo.ano}-${filters.ciclo.mes}`,
      }
    }
  }
  if (typeSelect?.id === 'Data') {
    paramsFilter = {
      intervalo: `${dateCustom(filters.dataInicial).data} ${dateCustom(filters.dataFinal).data}`,
    }
  }
  return paramsFilter
}

export const listarInfoGrupo = ({
  projetoID, 
  grupo, 
  filters={}, 
  typeSelect={}, 
  paginate = {pageNumber: 1, perPage: 7}
}) => {
  return dispatch => {
    dispatch(setLoadList(true))
    let paramsFilter = filtersCustom({filters, typeSelect})
    let params = paramsApi({
      projetoId: projetoID,
      paginaAtual: paginate.pageNumber?paginate.pageNumber:1,
      RPP: paginate.perPage?paginate.perPage:7,
      grupo: grupo.nomeGrupo,
      ...paramsFilter,
    })

    apiV3.get(`/Front/GetListaDeProdutos${params}`)
    //apiOld.get(`/Front/GetListaDeLojas${params}`)
    // api.get(`/InfoGrupo${params}`)
    .then(result => {
      const initialStatePaginate = { 
        pageNumber: result.data.pageAtual, 
        totalPages: result.data.totalPaginas,  
        totalElements: result.data.totalRegistros, 
        perPage: result.data.rpp
      }
      dispatch([
        setListarInfoGrupo(result.data.listaRegistros),
        setPaginateInfoGrupo(initialStatePaginate),
        setInfoTotalAvaliacao(result.data.totalAvaliacao),
        setLoadList(false)
      ])
    })
    .catch(err => {
        console.error(err);
        dispatch(setLoadList(false))
    });
  }
}

export const filterHandle = ({
  projetoID, 
  grupo, 
  params, 
  filtersProvisorio, 
  typeSelect, 
  paginate
}) => {
  const paramsCustom = {...params, ciclo: params?.ciclo?.ano ? params.ciclo : filtersProvisorio.ciclo}

  return dispatch => dispatch([
    setFiltered(paramsCustom),
    listarInfoGrupo({projetoID, grupo, filters: paramsCustom, paginate, typeSelect}),
  ])
}