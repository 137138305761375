import { history, idRdm, normalizeText, numberAleatori, paramsApi } from "../../../helpers"
import api from "../../../helpers/api"
import apiBase from "../../../helpers/apiBase"
import { modalAlertaClean, modalAlertaInfo, modalClose } from "../../../layout/redux/layoutActions"

export const setListaAssinaturas = payload => ({
  type: 'LIST_MINHAS_ASSINATURAS',
  payload
})

export const setMinhaAssinatura = payload => ({
  type: 'SET_MINHA_ASSINATURA',
  payload
})

export const cleanMinhaAssinatura = payload => ({
  type: 'CLEAN_MINHA_ASSINATURA',
  payload
})

export const setListaAtivos = payload => ({
  type: 'LIST_MINHAS_ASSINATURAS_ATIVOS',
  payload
})

export const setAtivos = payload => ({
  type: 'SET_MINHAS_ASSINATURAS_ATIVOS',
  payload
})

export const setAvaliacoes = payload => ({
  type: 'SET_MINHAS_ASSINATURAS_AVALIACOES',
  payload
})

export const setListAssinaturas = payload => ({
  type: 'LIST_ALL_ASSINATURAS',
  payload
})

export const setListMeuProjeto = payload => ({
  type: 'SET_MEUS_PROJETOS',
  payload
})

export const putListMeuProjeto = payload => ({
  type: 'PUT_MEUS_PROJETOS',
  payload
})


export const listarAssinaturas = e => {
  console.warn(e, 'listarAssinaturas');
  return dispatch => {
    // api.get(`/Projeto/ListaProjetos`)
    apiBase.post(`/v4/api/ListaAssinaturasXD`)
    .then(({data}) => {
      console.log(data, 'listarAssinaturas data');
      dispatch(setListaAssinaturas(data))
    })
    .catch(err => {
        console.error(err, 'listarAssinaturas');
    });
  }
}

export const listarAtivos = (e, moeda, tipo, status) => {
  console.warn(e, 'listarAtivos');
  let list = {}
  for (const key in e) {
    if (key.includes("Permitid")) {
      list = {...list, [key.replace('PermitidosConta', '').replace('PermitidasConta', '')]: e[key] }
    }
  }
  return dispatch => {
    apiBase.get(`/v4/api/ListaAtivos`)
    .then(({data}) => {
      console.log(data, 'listarAtivos data');
      data = data.map(d=> {
        const min = status === 'vazio' ? 0 : d.id <= 2 ? d.id : 0
        const valor = status === 'vazio' ? 0 :list[`${normalizeText(d.descricao)}`]
        return {...d, valor, quantidadeDesejada: min }
      })
      dispatch(setListaAtivos(data))
      if (tipo === 'CE') {
        dispatch(calculadoraContaEmpresarial({ativos: data, moeda}))
      } else {
        dispatch(calculadoraCompraDeAtivos({ativos: data, moeda}))
      }
    })
    .catch(err => {
        console.error(err, 'listarAtivos');
    });
  }
}

export const calculadoraCompraDeAtivos = ({ativos, moeda}) => {
  console.warn({ativos, moeda}, 'calculadoraCompraDeAtivos');
  const params = {
    ativos
  }
  return dispatch => {
    apiBase.post(`/v4/api/CalculadoraCompraDeAtivosXD`, params)
    .then(({data}) => {
      console.log(data, 'calculadoraCompraDeAtivos data');
      dispatch(setAvaliacoes(data))
    })
    .catch(err => {
        console.error(err, 'calculadoraCompraDeAtivos');
    });
  }
}

export const calculadoraContaEmpresarial = ({ativos, moeda}) => {
  console.warn({ativos, moeda}, 'calculadoraContaEmpresarial');
  const params = {
    moeda,
    ativos
  }
  return dispatch => {
    apiBase.post(`/v4/api/CalculadoraContaEmpresarialXD`, params)
    .then(({data}) => {
      console.log(data, 'calculadoraContaEmpresarial data');
      dispatch(setAvaliacoes(data))
    })
    .catch(err => {
        console.error(err, 'calculadoraContaEmpresarial');
    });
  }
}

export const listaAlterarAssinatura = e => {
  console.warn(e, 'listaAlterarAssinatura');
  const params = paramsApi(e)
  return dispatch => {
    apiBase.get(`/v4/api/ListaAlteracaoProjetoPronto${params}`)
    .then(({data}) => {
      console.log(data, 'listaAlterarAssinatura data');
      dispatch(setListAssinaturas({name: 'alterarAssinaturas', value: data.valores}))
      // dispatch(setListaAtivos([...e, {...data, id: 'avaliacoes-mensais-adicionais'}]))
    })
    .catch(err => {
        console.error(err, 'listaAlterarAssinatura');
    });
  }
}

export const listaEconomizarAssinatura = e => {
  console.warn(e, 'listaEconomizarAssinatura');
  const params = paramsApi(e)
  return dispatch => {
    apiBase.get(`/v4/api/ListaEconomizarConta${params}`)
    .then(({data}) => {
      console.log(data, 'listaEconomizarAssinatura data');
      dispatch(setListAssinaturas({name: 'economizarAssinatura', value: data.valores}))
      // dispatch(setListaAtivos([...e, {...data, id: 'avaliacoes-mensais-adicionais'}]))
    })
    .catch(err => {
        console.error(err, 'listaEconomizarAssinatura');
    });
  }
}

export const listaPeriodos = e => {
  console.warn(e, 'ListaPeriodos');
  return dispatch => {
    apiBase.get(`/v4/api/ListaPeriodos`)
    .then(({data}) => {
      console.log(data, 'ListaPeriodos data');
      dispatch(setListAssinaturas({name: 'periodos', value: data}))
    })
    .catch(err => {
        console.error(err, 'ListaPeriodos');
    });
  }
}

export const cancelarAssinatura = (e, nlsPopup) => {
  delete e.actions
  delete e.dataRenovacaoCustom
  delete e.statusCustom
  console.warn(e, 'cancelarAssinatura');
  const params = {
    ...e
  }
  const url = e.idProjetoPronto === null ? 'AgendarCancelamentoAssinaturaContaXD' : `AgendarCancelamentoAssinaturaProjetoProntoXD?projetoId=${e.idProjetoPronto}`
  return dispatch => {
    apiBase.post(`/v4/api/${url}`, params)
    .then(({data}) => {
      console.log(data, 'cancelarAssinatura data');
      dispatch(listarAssinaturas())
      // dispatch(setListaAtivos([...e, {...data, id: 'avaliacoes-mensais-adicionais'}]))
    })
    .catch(err => {
        console.error(err, 'cancelarAssinatura');
        dispatch(modalClose())
        dispatch(modalAlertaInfo({
          ...nlsPopup['7'], 
          btnAction: ()=> dispatch(modalAlertaClean()),
          show: true,
        }))
    });
  }
}

export const CheckoutAtivosAvulsosXD = (e, nlsPopup) => {
  console.warn(e, 'CheckoutAtivosAvulsosXD');
  const params = {
    ...e
  }
  return dispatch => {
    apiBase.post(`/v4/api/CheckoutAtivosAvulsosXD`, params)
    .then(({data}) => {
      console.log(data, 'CheckoutAtivosAvulsosXD data');
      window.open(data.urlCheckout, '_blank');
      dispatch(modalClose())
    })
    .catch(err => {
      dispatch(modalClose())
      dispatch(modalAlertaInfo({
        ...nlsPopup['7'], 
        btnAction: ()=> dispatch(modalAlertaClean()),
        show: true,
      }))
    });
  }
}

export const EconomizarAssinaturaContaXD = (e, nlsPopup) => {
  console.warn(e, 'EconomizarAssinaturaContaXD');
  const params = {
    ...e
  }
  return dispatch => {
    apiBase.post(`/v4/api/EconomizarAssinaturaContaXD`, params)
    .then(({data}) => {
        dispatch(modalAlertaClean())
        dispatch(modalClose())
    })
    .catch(({response: {data}}) => {
      dispatch(modalClose())
      if (data.iderro) {
        dispatch(modalAlertaInfo({
          ...nlsPopup['9'], 
          btnAction: ()=> dispatch(modalAlertaClean()),
          show: true,
        }))
      } else {
        dispatch(modalAlertaInfo({
          ...nlsPopup['7'], 
          btnAction: ()=> dispatch(modalAlertaClean()),
          show: true,
        }))
      }
    });
  }
}

export const EconomizarAssinaturaProjetoProntoXD = (e, nlsPopup) => {
  console.warn(e, 'EconomizarAssinaturaProjetoProntoXD');
  const params = {
    ...e
  }
  return dispatch => {
    apiBase.post(`/v4/api/EconomizarAssinaturaProjetoProntoXD`, params)
    .then(({data}) => {
      dispatch(modalAlertaClean())
      dispatch(modalClose())
    })
    .catch(({response: {data}}) => {
      dispatch(modalClose())
      if (data?.iderro) {
        dispatch(modalAlertaInfo({
          ...nlsPopup['9'], 
          btnAction: ()=> dispatch(modalAlertaClean()),
          show: true,
        }))
      } else {
        dispatch(modalAlertaInfo({
          ...nlsPopup['7'], 
          btnAction: ()=> dispatch(modalAlertaClean()),
          show: true,
        }))
      }
    });
  }
}

export const AtualizarAssinaturaContaXD = (e, nlsPopup) => {
  console.warn(e, 'AtualizarAssinaturaContaXD');
  const params = {
    ...e
  }
  return dispatch => {
    apiBase.post(`/v4/api/AtualizarAssinaturaContaXD`, params)
    .then(({data}) => {
      dispatch(modalAlertaClean())
      dispatch(modalClose())
      dispatch(listarAssinaturas())
    })
    .catch(({response: {data}}) => {
      dispatch(modalClose())
      if (data?.iderro) {
        dispatch(modalAlertaInfo({
          ...nlsPopup['8'], 
          btnAction: ()=> dispatch(modalAlertaClean()),
          show: true,
        }))
      } else {
        dispatch(modalAlertaInfo({
          ...nlsPopup['7'], 
          btnAction: ()=> dispatch(modalAlertaClean()),
          show: true,
        }))
      }
    });
  }
}

export const AtualizarAssinaturaProjetoProntoXD = (e, nlsPopup) => {
  console.warn(e, 'AtualizarAssinaturaProjetoProntoXD');
  const params = {
    ...e
  }
  return dispatch => {
    apiBase.post(`/v4/api/AtualizarAssinaturaProjetoProntoXD`, params)
    .then(({data}) => {
      dispatch(modalAlertaClean())
      dispatch(modalClose())
      dispatch(listarAssinaturas())
    })
    .catch(({response: {data}}) => {
        dispatch(modalClose())
        if (data?.iderro) {
          dispatch(modalAlertaInfo({
            ...nlsPopup['8'], 
            btnAction: ()=> dispatch(modalAlertaClean()),
            show: true,
          }))
        } else {
          dispatch(modalAlertaInfo({
            ...nlsPopup['7'], 
            btnAction: ()=> dispatch(modalAlertaClean()),
            show: true,
          }))
        }
    });
  }
}